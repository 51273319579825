<template>
  <section class="ecommerce-application app-ecommerce-details">

    <b-overlay v-if="product && productLoaded" spinner-medium>
      <b-card no-body>
        <b-card-body>
          <b-row class="my-2">
            <b-col class="d-flex align-items-start justify-content-center mb-2 mb-md-0 mt-2" cols="4">
              <div v-if="product.mainVariant.files.length < 2" class="d-flex align-items-center justify-content-center">
                <div class="product-img">
                  <b-img
                    :src="product.mainVariant.files.length ? product.mainVariant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')"
                    fluid
                  />
                </div>
              </div>
              <div v-else class="d-flex align-items-center justify-content-center w-100">
                <swiper :options="swiperOptions" class="swiper-responsive-breakpoints">
                  <swiper-slide v-for="file in product.mainVariant.files" :key="file.id">
                    <div class="img-container">
                      <b-img
                        :src="file.fileUrls[300]"
                        fluid
                      />
                    </div>
                  </swiper-slide>
                  <!-- Add Arrows -->
                  <div slot="button-next" class="swiper-button-next" />
                  <div slot="button-prev" class="swiper-button-prev" />
                </swiper>
              </div>
            </b-col>

            <b-col cols="8">
              <div v-if="product.mainVariant.floorArea" class="d-flex align-items-start justify-content-end mb-1 mt-1">
                <b-badge v-if="available" class="badge-price" variant="success"> {{ $t('trip.hangar.available') }}</b-badge>
                <b-badge v-else class="badge-price" variant="warning"> {{ $t('trip.hangar.availability_subject_to_confirmation') }}</b-badge>
              </div>
              <div>
                <h2>{{ product.name | trans }}</h2>
                <p>{{ product.mainCategory.name | trans }}</p>
                <p>{{ $t('common.by') }} {{ product.organization.name }}</p>
                <div v-if="product.mainVariant && product.mainVariant.packing" class="mt-1">
                  <p class="pb-0 mb-0"> {{ $t('service.prices.type') }} : {{ product.mainVariant.pricingType | enumTranslate('pricing_type') }}</p>
                  <p class="pb-0 mb-0"> {{ $t('service.prices.type_measurement') }} : {{ product.mainVariant.packing.name | trans }}</p>
                </div>
                <div v-if="product.minPrice">
                  <p class="pb-0 mb-0">{{ $t('pricing.unit_price_before_tax') }} : {{ product.minPrice.displayPriceExcludingTaxes | priceFormat }}</p>
                  <p class="pb-0 mb-0">{{ $t('pricing.unit_price_including_tax') }} : {{ product.minPrice.displayPriceIncludingTaxes | priceFormat }}</p>
                </div>

                <h5 class="font-weight-bold">{{ $t('service.technical_informations.title') }}</h5>
                <ul class="list-unstyled">
                  <li v-if="product.mainVariant.floorArea">{{ $t('service.technical_informations.net_floor_surface_for_rent') }} : {{ product.mainVariant.floorArea }} {{ $getMeasurementUnitItn('m') }}² </li>
                  <li v-if="product.mainVariant.maxDoorHeight">{{ $t('service.technical_informations.door_opening_height') }} : {{ product.mainVariant.maxDoorHeight }} {{ $getMeasurementUnitItn('m') }} </li>
                  <li v-if="product.mainVariant.maxDoorWidth">{{ $t('service.technical_informations.door_opening_width') }} : {{ product.mainVariant.maxDoorWidth }} {{ $getMeasurementUnitItn('m') }} </li>
                </ul>
                <ul>
                  <li v-for="attribute in product.mainVariant.variantAttributes" :key="attribute.id">
                    {{ attribute.attribute.name | trans }} :
                    <span v-if="attribute.inputValues">{{ attribute.inputValues | trans }}</span>
                    <span v-if="attribute.selectValue">{{ attribute.selectValue.name | trans }}</span>
                    <ul v-if="attribute.choiceValues">
                      <li v-for="choice in attribute.choiceValues" :key="choice.id">
                        {{ choice.name | trans }}
                      </li>
                    </ul>
                  </li>
                </ul>
                <h5 v-if="$route.query.start_at || $route.query.end_at" class="font-weight-bold">{{ $t('trip.hangar.time_of_stay') }}</h5>
                <b-overlay :show="!tripStep">
                  <ul v-if="$route.query.start_at || $route.query.end_at" class="list-unstyled">
                    <li v-if="$route.query.start_at">
                      {{ $t('common.start_at') }} : {{ $moment.utc($route.query.start_at).format('ddd, MMM Do YYYY HH:mm [UTC] ') }}
                      <span v-if="tripEvent && tripEvent.airport">
                        ({{ $t('common.local_time') }} :
                        {{
                          $moment
                            .utc($route.query.start_at)
                            .utcOffset(tripEvent.airport.timeZone)
                            .format('ddd, MMM Do YYYY HH:mm')
                        }})
                      </span>
                    </li>
                    <li v-if="$route.query.end_at">
                      {{ $t('common.end_at') }} : {{ $moment.utc($route.query.end_at).format('ddd, MMM Do YYYY HH:mm [UTC] ') }}
                      <span v-if="tripEvent && tripEvent.airport">
                        ({{ $t('common.local_time') }} :
                        {{
                          $moment
                            .utc($route.query.end_at)
                            .utcOffset(tripEvent.airport.timeZone)
                            .format('ddd, MMM Do YYYY HH:mm')
                        }})
                      </span>
                    </li>
                    <li v-if="durationCalculated() === 1"> {{ durationCalculated() }} {{ $t('trip.hangar.day') }} </li>
                    <li v-else> {{ durationCalculated() }} {{ $t('trip.hangar.days') }} </li>
                  </ul>
                </b-overlay>
                <h5 v-if="product.minPrice" class="font-weight-bold">{{ $t('trip.hangar.price') }}</h5>
                <ul v-if="product.minPrice" class="list-unstyled">
                  <li v-if="product.minPrice">{{ $t('trip.cost.total_before_tax') }} : {{ totalPrice(product.minPrice.displayPriceExcludingTaxes, durationCalculated()) | priceFormat }}</li>
                  <li v-if="product.minPrice">{{ $t('trip.cost.total_including_tax') }} : {{ totalPrice(product.minPrice.displayPriceIncludingTaxes, durationCalculated()) | priceFormat }}</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-end mb-1 mt-4">
                <h4 class="item-price m-0">
                  <span v-if="!product.minPrice || !$route.query.start_at || !$route.query.end_at" class="h5">
                    {{ $t('trip.shop.price_on_quote') }}
                  </span>
                  <span v-else> {{ totalPrice(product.minPrice.displayPriceIncludingTaxes, durationCalculated()) | priceFormat }} {{ $t('trip.hangar.incl_taxes') }}</span>
                </h4>
                <b-button v-if="!selected" :disabled="!($route.query.start_at && $route.query.end_at)" class="btn-cart ml-1" @click="confirmation(product)">
                  <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                  <span>{{ $t('trip.shop.add_to_cart') }}</span>
                </b-button>
                <b-button
                  v-else
                  :to="{
                    name: 'shop-checkout',
                    params: {
                      provider_id: product.organization.id,
                      trip_id: $route.params.trip_id,
                      step_id: $route.params.step_id,
                      event_id: $route.params.event_id,
                      airport_id: $route.params.airport_id,
                    },
                  }"
                  class="btn-cart ml-1"
                >
                  <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                  <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>

      </b-card>

      <b-card v-if="product.mainVariant.description && product.mainVariant.description[userLang]">
        <h3>{{ $t('trip.hangar.description') }}</h3>
        <div v-html="product.mainVariant.description[userLang]" />
      </b-card>
    </b-overlay>

    <HangarConfirmation
      v-if="tripEvent"
      :confirmation-edition="confirmationEdition"
      :date="date"
      :selected-product="selectedProduct"
      :trip-step-event="tripEvent"
      @update:offer="postOffer($event)"
      @update:edition="confirmationEdition = $event"
    />
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { fetchHangarProductDetailRequest } from '@/request/globalApi/requests/apiRequests'
import Cart from '@/models/Cart'
import HangarConfirmation from '@/views/provider/hangar/HangarConfirmation.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'HangarDetail',
  components: {
    HangarConfirmation,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      productLoaded: false,
      product: null,
      productQuantity: 1,
      relatedProducts: null,
      cart: new Cart(),
      selected: '',
      available: null,
      tripData: {},
      tripStep: null,
      tripEvent: null,
      confirmationEdition: false,
      selectedProduct: null,
      date: {
        start: {
          date: '',
          time: '',
        },
        end: {
          date: '',
          time: '',
        },
      },
    }
  },
  computed: {
    ...mapState('appConfig', ['lang']),
    ...mapState('trip', ['trip']),
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  watch: {
    $route() {
      this.APIFetchProduct()
    },
  },
  mounted() {
    this.APIFetchProduct()
    this.fetchTripInfos()
    if (this.$route.query.start_at && this.$route.query.end_at) {
      this.date.start.time = this.$moment.utc(this.$route.query.start_at).format('HH:mm')
      this.date.start.date = this.$moment.utc(this.$route.query.start_at).format('YYYY-MM-DD')
      this.date.end.time = this.$moment.utc(this.$route.query.end_at).format('HH:mm')
      this.date.end.date = this.$moment.utc(this.$route.query.end_at).format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapActions('trip', ['fetchTrip']),

    async fetchTripInfos() {
      const eventId = Number(this.$route.params.event_id)
      const tripId = Number(this.$route.params.trip_id)
      this.tripData = await this.fetchTrip({ tripId })
      if (this.tripData) {
        this.tripStep = this.trip.tripSteps.find(step => step.flightArrival.id === eventId || step.flightDeparture.id === eventId)
        this.tripEvent = this.tripStep.flightArrival.id === eventId ? this.tripStep.flightArrival : this.tripStep.flightDeparture
      }
    },
    APIFetchProduct() {
      this.productLoaded = false
      const productId = this.$route.params.product_id
      const organizationId = this.$route.params.provider_id
      const tripStepId = this.$route.params.event_id
      const params = {
        startAt: this.$route.query.start_at,
        endAt: this.$route.query.end_at,
      }

      fetchHangarProductDetailRequest(tripStepId, organizationId, productId, params)
        .then(r => {
          const { product, selected, available } = r.data
          this.product = product
          this.selected = selected
          this.available = available
        })
        .catch(() => {
          this.product = undefined
        })
        .finally(() => {
          this.productLoaded = true
        })
    },
    confirmation(product) {
      this.selectedProduct = product
      this.confirmationEdition = true
    },
    postOffer() {
      this.selected = true
    },
    durationCalculated() {
      const startDate = this.$moment.duration(this.$moment.utc(this.$route.query.start_at))
      const endDate = this.$moment.duration(this.$moment.utc(this.$route.query.end_at))

      return (endDate.subtract(startDate).days() + 1)
    },
    totalPrice(unitPrice, occupancyDays) {
      return unitPrice * occupancyDays
    },
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }

    return {
      swiperOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '../../../../node_modules/swiper/css/swiper.css';
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
